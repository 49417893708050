import React from "react";

export default function Project() {
  return (
    <div
      className="flex flex-col justify-center items-center gap-28 bg-blue-600 h-[100vh] text-white"
      id="project"
    >
      <h1 className="text-lg">This is my project</h1>
    </div>
  );
}
