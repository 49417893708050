import {useEffect, useState} from "react"
import Body from "./components/Body/Body"
import Contact from "./components/Contact/Contact"
import Navbar from "./components/Navbar/Navbar"
import Project from "./components/Project/Project"

function App() {
  // const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  // useEffect(() => {
  //   const mouseMove = (e) => {
  //     setMousePosition({ x: e.clientX, y: e.clientY });
  //   };
  //   window.addEventListener("mousemove", mouseMove);
  //   return () => {
  //     window.removeEventListener("mousemove", mouseMove);
  //   };
  // }, [mousePosition]);

  // const variants = {
  //   default: {
  //     x: mousePosition.x - 15,
  //     y: mousePosition.y - 15,
  //   },
  // };

  return (
    <>
      <div className="smooth-scroll">
        <Navbar />
        <main>
          <div>
            <Body />
          </div>
          <div>
            <Project />
          </div>
          <div>
            <Contact />
          </div>
        </main>
      </div>
      {/* <motion.div className="cursor" variants={variants} animate="default" /> */}
    </>
  )
}

export default App
