import React from "react"
import {Link} from "react-scroll"

export default function Navbar() {
  return (
    <nav className="flex fixed w-full p-4 bg-black text-white">
      <div className="flex mx-auto justify-between items-center w-2/3">
        <div className="hover:scale-125 duration-100 hover:uppercase hover:underline cursor-pointer">
          <Link to="home" smooth={true} duration={500}>
            <h1 className="text-2xl font-bold">Dinesh's Portfolio</h1>
          </Link>
        </div>
        <div>
          <ul className="flex gap-6 text-lg font-semibold mr-5">
            <li className=" hover:scale-125 duration-100 hover:underline cursor-pointer">
              <Link to="home" smooth={true} duration={500}>
                Home
              </Link>
            </li>
            <li className=" hover:scale-125 duration-100 hover:underline cursor-pointer">
              <Link to="project" smooth={true} duration={500}>
                Project
              </Link>
            </li>
            <li className=" hover:scale-125 duration-100 hover:underline cursor-pointer">
              <Link to="contact" smooth={true} duration={500}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
