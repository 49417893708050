import React from "react";
import {LiaLinkedin} from "react-icons/lia";
import {FiGithub} from "react-icons/fi";
import TechStack from "./TechStack";

export default function Body() {
  return (
    <div id="home" className="flex flex-col justify-center items-center gap bg-orange-300 h-[100vh] text-black">
      {/* <div class="cursor-dot"></div>
      <div class="cursor-outline"></div> */}
      <div className="flex justify-center items-center gap-10  w-2/3 mt-20">
        <div className="flex flex-col gap-3 ">
          <h1 className=" text-6xl">Back-End Developer</h1>
          <p className="text-xl">Hi, I'm Dinesh Thakuri. A starter in front end development.</p>
          <p className="text-xl">Developer based in Nepal, Pokhara</p>
          <div className="flex items-left gap-5 w-2/3 mt-4">
            <LiaLinkedin size={40} />
            <FiGithub size={35} />
          </div>
        </div>
        <img src="/avatar.jpg" alt="avatar" className="w-[300px] rounded-full object-cover" />
      </div>
      <TechStack />
    </div>
  );
}
