import React from "react";
import { FaHtml5, FaCss3Alt } from "react-icons/fa";
import { SiJavascript } from "react-icons/si";
import { SiTypescript } from "react-icons/si";
import { FaReact } from "react-icons/fa";
import { RiTailwindCssFill } from "react-icons/ri";

export default function TechStack() {
  return (
    <div className="p-5 m-8">
      <div className="flex justify-center items-center gap-7 p-7">
        <h1 className="font-semibold text-xl border-r px-7">Tech Stack</h1>
        <div className="flex gap-3 transition-all duration-400 hover:scale-110">
          <FaHtml5 size={40} />
          <FaCss3Alt size={40} />
        </div>
        <div className="flex gap-3 transition-all duration-400 hover:scale-110">
          <SiJavascript size={40} />
          <SiTypescript size={40} />
        </div>
        <div className="flex gap-3 transition-all duration-400 hover:scale-110">
          <FaReact size={40} />
          <RiTailwindCssFill size={40} />
        </div>
      </div>
    </div>
  );
}
