import React from "react"

export default function Contact() {
  return (
    <div className="flex justify-center items-center bg-red-600 h-[100vh] text-white" id="contact">
      <div className="flex flex-col gap-6 bg-red-400 p-8 rounded-3xl shadow-lg w-1/3">
        <h1 className="text-4xl font-bold text-center">Contact Me</h1>
        <form className="flex flex-col space-y-6 gap-2">
          <div className="flex flex-col">
            <label className="text-lg font-semibold mb-2 text-red-800">
              Name <span className="text-red-800">*</span>
            </label>
            <input
              placeholder="Enter your name"
              required
              className="rounded-lg px-3 py-2 text-red-800 bg-red-200 focus:outline-none focus:ring focus:ring-red-400"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-lg font-semibold mb-2 text-red-800">
              Phone Number <span className="text-red-800">*</span>
            </label>
            <input
              placeholder="Enter your phone number"
              type="tel"
              required
              className="rounded-lg px-3 py-2 text-red-800 bg-red-200 focus:outline-none focus:ring focus:ring-red-400"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-lg font-semibold mb-2 text-red-800">
              Email <span className="text-red-800">*</span>
            </label>
            <input
              placeholder="Enter your email"
              type="email"
              required
              className="rounded-lg px-3 py-2 text-red-800 bg-red-200 focus:outline-none focus:ring focus:ring-red-400"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-lg font-semibold mb-2 text-red-800">
              Description <span className="text-red-800">*</span>
            </label>

            <textarea
              placeholder="Your Description"
              required
              className="rounded-lg px-3 py-2 text-red-800 bg-red-200 focus:outline-none focus:ring focus:ring-red-400"
            />
          </div>

          <button
            type="submit"
            className="mt-4 bg-red-800 hover:bg-red-900 text-white font-bold py-2 px-4 rounded-lg transition duration-200">
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}
